import React, { useRef } from "react"
import { Link, graphql, navigate } from "gatsby"
import Image from "gatsby-image"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import LoadChapterTemplate from "./load-chapter"

export const query = graphql`
  query($slug: String!) {
    strapiManga(slug: { eq: $slug }) {
      title
      description
      slug
      hero {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chapters {
        number
      }
    }
  }
`

const MangaTemplate = ({ data: { strapiManga: manga }, location }) => {
  const { state } = location
  const sortedChapters = manga.chapters.sort((A, B) => A.number - B.number)
  const firstChapter = [...sortedChapters].shift()
  const lastChapter = [...sortedChapters].pop()

  const selectEl = useRef(null)

  const loadChapter = chapterNumber => {
    navigate(`${manga.slug}/capitulo/${chapterNumber}`)
  }

  if (state && state.loadNewChapter) {
    return (
      <LoadChapterTemplate
        lastChapterRead={state.lastChapterRead}
        manga={manga}
      />
    )
  }

  return (
    <Layout>
      <section
        css={css`
          width: 100%;
          display: flex;
          justify-content: center;
        `}>
        <article
          css={css`
            width: 100%;
            max-width: 1400px;
            min-height: 500px;
            display: grid;
            grid-template-columns: 1f;
            background: white;
            border: 2px solid #ececec;

            border-radius: 20px;
            @media (min-width: 900px) {
              grid-template-columns: 1fr 1fr;
            }
          `}>
          <div
            css={css`
              max-width: 800px;
              height: 500px;
            `}>
            <Image
              fluid={manga.hero.sharp.fluid}
              imgStyle={{ objectFit: "fill" }}
              css={css`
                width: 100%;
                min-height: 500px;
                height: auto;
                border: 2px solid #ececec;
                * {
                  margin-top: 0;
                }
                @media (min-width: 900px) {
                  border-radius: 20px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px;
                }
              `}
            />
          </div>
          <div
            css={css`
              width: calc(100% - 2.4rem);
              padding: 1.2rem;
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              flex-wrap: wrap;
              * {
                text-align: justify;
              }
            `}>
            <h2
              css={css`
                font-family: "Montserrat", sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
              `}>
              {manga.title}
            </h2>
            <p
              css={css`
                height: 200px;
                overflow: hidden;
              `}>
              {manga.description}
            </p>
            {manga.chapters.length > 0 && (
              <div
                css={css`
                  width: 100%;
                  flex-grow: 1;
                  margin: 0.8rem;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  > * {
                    margin-bottom: 0.4rem;
                  }
                  a {
                    width: 100%;
                    text-align: center;
                  }
                  button,
                  select {
                    text-align: center;
                    text-align-last: center;
                    width: 90%;
                    max-width: 300px;
                    padding: 0.8rem 1.6rem;
                    cursor: pointer;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 700;
                  }
                  button:hover {
                    border-color: transparent;
                    background: midnightblue;
                    color: white;
                  }
                `}>
                <Link to={`${manga.slug}/capitulo/${firstChapter.number}`}>
                  <button>EMPEZAR MANGA</button>
                </Link>

                <Link to={`${manga.slug}/capitulo/${lastChapter.number}`}>
                  <button>ÚLTIMO CAPÍTULO</button>
                </Link>
                <select
                  ref={selectEl}
                  onChange={() => loadChapter(selectEl.current.value)}>
                  <option>SELECIONAR CAPÍTULO</option>
                  {sortedChapters.reverse().map(chapter => (
                    <option value={chapter.number}>{chapter.number}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </article>
      </section>
    </Layout>
  )
}

export default MangaTemplate
