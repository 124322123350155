import React, { useRef } from "react"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { navigate } from "gatsby"
import Layout from "../components/layout"

const LoadChapterTemplate = ({ manga, lastChapterRead }) => {
  const selectEl = useRef(null)
  
  const nextChapter = (
    manga.chapters.find(chapter => chapter.number === lastChapterRead + 1) || {}
  ).number

  const loadChapter = chapterNumber => {
    navigate(`${manga.slug}/capitulo/${chapterNumber}`)
  }

  const sortedChapters = manga.chapters.sort((A, B) => A.number - B.number)

  return (
    <Layout>
      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
        `}>
        <h2
          css={css`
            text-transform: uppercase;
            text-align: center;
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
          `}>
          {manga.title}
        </h2>
        <Img
          fluid={manga.hero.sharp.fluid}
          imgStyle={{ objectFit: "fill" }}
          css={css`
            width: 100%;
            * {
              margin-top: 0;
            }

            @media (min-width: 500px) {
              height: 400px;
            }
          `}
        />
      </div>
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          
          button,
          select {
            text-align: center;
            text-align-last: center;
            width: 90%;
            max-width: 300px;
            margin: 0.8rem;
            padding: 0.8rem 1.6rem;
            cursor: pointer;
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
          }
          button:hover {
            border-color: transparent;
            background: midnightblue;
            color: white;
          }
        `}>

        <button onClick={() => loadChapter(lastChapterRead)}>
          CAPÍTULO ANTERIOR
        </button>
        <select ref={selectEl} onChange={() => loadChapter(selectEl.current.value)}>
          <option>SELECIONAR CAPÍTULO</option>
          {sortedChapters.reverse().map(chapter => (
            <option value={chapter.number}>{chapter.number}</option>
          ))}
        </select>
        {nextChapter && (
          <button onClick={() => loadChapter(nextChapter)}>
            SIGUIENTE CAPÍTULO
          </button>
        )}
      </div>
    </Layout>
  )
}

export default LoadChapterTemplate
